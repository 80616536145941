define([
	"OApp",
	"OUtils",
	"js/GoogleMaps",
	"referrals/Referrals",
	"dwr/interface/ReferralPortalRpc",
	"js/History",
	"referrals/directory/MatchDetailsView",
	"referrals/directory/MatchingListingsView",
	"referrals/directory/ReferralFavouritesView",
	"referrals/directory/MapView",
	"referrals/directory/OhmSidebar"
], function(OApp, OUtils, GoogleMaps, Referrals, ReferralPortalRpc,
			History, MatchDetailsView, MatchingListingsView, ReferralFavouritesView, MapView, OhmSidebar) {
	"use strict";
	var pane = null, leftPane = null, rightPane = null, mainPanel, testModeBanner;
	var pt = null;
	var encryptedPt = null;
	var matches = [], searchTitle = null, searchMsg = null, showWait2 = false;
	var searchLoc = null, preciseLoc = false;
	var criteria = {};
	var sortAsc = true, sortField = null;
	var urlArgs = OUtils.getURLVars();
	var searchArg = urlArgs["search"] || null;
	var rtRefArg = urlArgs["rtRef"] || null;
	var ptRefArg = sessionStorage.getItem("ptRef") || urlArgs["oceanPtRef"] || null;
	var referralRedirect = localStorage.getItem("referralRedirect") || null;
	if (referralRedirect != null) {
		referralRedirect = JSON.parse(referralRedirect);
		localStorage.removeItem("referralRedirect");
	}
	var selectedHs = urlArgs["hs"] || null;
	var selectedHso = urlArgs["hso"] || null; // Overrides a matching HSO from previous state
	var selfReferral = urlArgs["selfReferral"] === "true" || false;
	var favToChoose = urlArgs["refFav"];
	var patientFacing = urlArgs["patientFacing"] === "true" || false;
	var filterTags = function(v) { return v ? v.split(",") : []; }(urlArgs["filter"]);
	var selectedMatch = null;
	var SEARCH_LIMIT = 100;
	var ptLoaded = $.Deferred();
	var subPanelId;
	var testMode = false;
	var searchScoresEnabled = false;
	var inON = false;

	function init() {
		pane = $("body");
		require(["user/UserDashboard"], function(UserDashboard) {
			OApp.initTranslationFlagAndI18n({ namespaces: ["shared"] }, function() {
				UserDashboard.init({
					pane: pane.findIt("#userDashboard"),
					showSitesButton: true,
					loginCallback: onLogin
				});
			});
		});
		mainPanel = pane.findIt("#hlMainPanel");
		mainPanel.show();
		leftPane = pane.findIt("#ohl-left-panel");
		rightPane = pane.findIt("#ohl-right-panel");
		testModeBanner = pane.findIt("#testModeBanner");
		OApp.init({
			activateReverseAjax: false,
			sessionTimeoutHandler: function() {
				// This prevents the location.reload() which would otherwise discard Send Referral user-entered info.
				// We also want to avoid showing a popup dialog for ONE ID's sessionEnded() handler here
			}
		});
		OUtils.translateHtml(pane);
		initPanels();
		initHistory();
		var searchStarted = false;
		OApp.getUser(function(user) {
			if (user && user.orderSetsEnabled) {
				OhmSidebar.showManageOrderSets();
			}
		});
		OApp.getSite(function() {});
		redirectIfNoSiteYet();
		updateLogoLang();
		function updateLogoLang(){
			let currentLanguage = OUtils.getLocale();
			const imgSources = {
				'en': '/images/ereferral_network_logo_dark.png',
				'fr': '/images/ereferral_network_logo_dark_french.svg'
			};
			const logoImg = pane.findIt("#eRefNetworkLogo");
			if (imgSources[currentLanguage]) {
				logoImg.attr("src", imgSources[currentLanguage]);
			} else {
				logoImg.attr("src", imgSources['en']);
			}
		}

		return loadPatient()
			.then(ptLoaded.resolve)
			.then(function loadRegion() {
				var d = $.Deferred();
				if (!SearchPanel.hasAddress()) {
					GoogleMaps.getCurrentLocation(function(result) {
						if (result) {
							var neighborhood = GoogleMaps.getAddressComponent(result, "neighborhood");
							var subLocality = GoogleMaps.getAddressComponent(result, "sublocality");
							var locality = GoogleMaps.getAddressComponent(result, "locality");
							if (neighborhood){
								setLocation(neighborhood);
							}
							else if (subLocality){
								setLocation(subLocality);
							}
							else if (locality) {
								setLocation(locality);
							}
							else{
								defaultView();
							}
						}
						else {
							defaultView();
						}
						function setLocation(location){
							LocalityPref.save(location);
							SearchPanel.setAddressText(location);
						}
						function defaultView(){
							MapView.setZoom(4);
							SearchPanel.setAddressText(OUtils.t("Canada", "NewReferral.js.canada_label"));
						}
						d.resolve();
					});
				}
				else {
					d.resolve();
				}
				return d;
			})
			.then(loadTestMode)
			.then(SearchPanel.lookupAddress)
			.then(function() {
				return MapView.loaded;
			})
			.then(function() {
				if (urlArgs.riiId) {
					require(["referrals/IncompleteReferralLoader"], function(IncompleteReferralLoader) {
						OApp.initTranslationFlagAndI18n({ namespaces: ["pn-pe"] }, function() {
							IncompleteReferralLoader.open({id: urlArgs.riiId, accessKey: urlArgs.riiAccessKey, openSendDialog: startReferral});
						});
					});
				}
				else if (rtRefArg) {
					ReferralPortalRpc.getReferralTargetByRefForReferrer(rtRefArg, function(rt) {
						if (rt) {
							matches = [{rt: rt}];
							if (rt.offerings && rt.offerings.length === 1) {
								matches[0].offeringRef = rt.offerings[0].ref;
							}
							setSelectedMatch(matches[0]);
							displayMatches(matches);
						}
					});
					searchStarted = true;
				}
				if (!searchStarted) {
					if (selectedHs || searchArg || testMode) {
						startSearch();
					}
					else {
						ReferralFavouritesView.loaded
							.then(function() {
								showSubPanel(ReferralFavouritesView);
								History.save("Initial");
							});
					}
				}
			});
	}
	function setSort(newSortField) {
		if (sortField !== newSortField) {
			sortField = newSortField;
			startSearch();
		}
	}
	function loadTestMode() {
		var d = $.Deferred();
		OApp.getUser(function(user) {
			if (user && user.testMode) {
				testMode = true;
				testModeBanner.show();
				mainPanel.addClass('testMode');
			}
			if (user && user.admin) {
				OhmSidebar.showSearchScoreToggle();
			}
			OhmSidebar.setTestMode(testMode);
			d.resolve();
		});
		return d;
	}
	
	function redirectIfNoSiteYet() {
		require(["js/WelcomeRedirectHandler"], function(WelcomeRedirectHandler) {
			WelcomeRedirectHandler.redirectIfNoActiveSite(WelcomeRedirectHandler.HEALTHMAP);
		});
	}

	function onLogin(user) {
		redirectIfNoSiteYet();
		function storePtRef() {
			if (ptRefArg) {
				sessionStorage.setItem("ptRef", ptRefArg);
			}
		}
		OApp.clearTranslationFlag();
		OApp.initTranslationFlagAndI18n({ namespaces: ["pn-pe", "shared"] }, function() {
			if (user && OTranslate.hasTranslationCookie()) {
				// translation is enabled, see if page needs a refresh
				if (user.language && user.language !== OTranslate.getLocale()) {
					// user language pref doesn't match pref from before login, refresh page
					storePtRef();
					window.location.reload();
				}
			}
		});

		if (user && user.testMode) {
			testModeUpdated(user.testMode);
		} else {
			testModeUpdated(false);
		}
	}
	function testModeUpdated(mode) {
		if (mode === testMode) {
			return;
		}
		testMode = mode;
		OhmSidebar.setTestMode(testMode);
		testModeBanner.toggle(testMode);
		if (testMode) {
			if (isShowingSubPanel(MatchDetailsView) && MatchDetailsView.getMatch().rt.testListing) {
				MatchDetailsView.setMatch(MatchDetailsView.getMatch(), true);
				showSubPanel(MatchDetailsView);
			} else {
				startSearch();
			}
			mainPanel.addClass("testMode");
		} else {
			if (isShowingSubPanel(MatchDetailsView) && !MatchDetailsView.getMatch().rt.demoListing) {
				MatchDetailsView.setMatch(MatchDetailsView.getMatch(), false);
				showSubPanel(MatchDetailsView);
			} else {
				clearSearch();
			}
			mainPanel.removeClass("testMode");
		}
	}
	function enableSearchScores(mode) {
		if (mode === searchScoresEnabled) {
			return;
		}
		searchScoresEnabled = mode;
		OhmSidebar.setSearchScores(searchScoresEnabled);
	}
	function initPanels() {
		SearchPanel.init({
			pane: pane.findIt("#searchPanel"),
			patientFacing: patientFacing
		});
		MapView.init({
			pane: rightPane.findIt("#hlMapBorder"),
			getPt: getPt,
			clearAddressText: function(){
				SearchPanel.setAddressText("");
			},
			clearPopularListings: function hidePopularListings() {
				ReferralFavouritesView.hideRegionalFavs();
			},
			updateSearchCb: function() {
				searchLoc = MapView.getCentre();
				preciseLoc = false;
				SearchPanel.setAddressText("");
				startSearch();
			},
			matchSelectedCb: function(match) {
				setSelectedMatch(match);
			},
			hiResStar: urlArgs["hiResStar"] === "true"
		});
		MatchingListingsView.init({
			matchSelectedCb: function(match) {
				setSelectedMatch(match);
			},
			clearSearch: clearSearch,
			startSearch: startSearch,
			setSort: setSort,
			matchFocusedCb: matchFocused
		});
		MatchDetailsView.init({
			getPt: getPt,
			startReferral: startReferral,
			closeListing: function () {
				if (matches.length === 1) {
					clearSearch();
				} else {
					showSubPanel(MatchingListingsView);
					//MapView.clear();
					MapView.zoomToBestFit();
				}
				History.save("Listing Closed");
			},
			patientFacing: patientFacing
		});
		ReferralFavouritesView.init({
			favToChoose: favToChoose,
			regionalCentrePromise: $.Deferred(),
			favouriteChosenCb: function(refFav, rt) {
				if(refFav && refFav.offeringRef) {
					// offering favourite
					setMatch({rt: rt, offeringRef: refFav.offeringRef});
				} else {
					// listing favourite
					setMatch({rt: rt});
				}
			},
			startReferral: startReferral
		});
		OhmSidebar.init({
			pane: pane,
			showSubPanel: showSubPanel,
			setMatches: setMatches,
			startSearch: startSearch,
			hsSelectedCb: function(hs) {
				SearchPanel.setHs(hs);
			},
			testModeUpdated: testModeUpdated,
			enableSearchScores: enableSearchScores,
			clearSearch: clearSearch,
		});
		if (ptRefArg) {
			OApp.showAnonymousLoginPrompt(function(user) {
				onLogin(user);
			});
		}
	}
	function initHistory() {
		History.addListener({
			name: "HSDirectory",
			getState: function() {
				return $.extend(true, {
					selectedHs: selectedHs,
					selectedMatch: selectedMatch,
					matches: JSON.stringify(matches),
					searchTitle : searchTitle,
					searchMsg: searchMsg,
					subPanelId: subPanelId
				}, SearchPanel.getState());
			},
			setState: function(state) {
				selectedHs = state.selectedHs;
				SearchPanel.setState(state);
				searchTitle = state.searchTitle;
				searchMsg = state.searchMsg;
				setMatches(state.matches ? JSON.parse(state.matches) : []);
				if (state.selectedMatch) {
					setSelectedMatch(state.selectedMatch);
				}
				else {
					selectedMatch = null;
				}
				restoreSubPanel(state.subPanelId);
			}
		});
	}
	function getPt(cb) {
		cb(pt, encryptedPt);
	}
	function startReferral(options) {
		ptLoaded.then(function() {
			require(["referrals/SendReferralDialog"], function(SendReferralDialog) {
				OApp.initTranslationFlagAndI18n({ namespaces: ["pn-pe", "shared"] }, function() {
					SendReferralDialog.show({
						showDialog: true,
						rii: options.rii,
						referral: options.referral,
						rt: options.rt,
						pt: options.pt || pt, // this will be loaded in from sessionStorage, through URLparams, or a referralRedirect from localStorage
						referralRedirect: referralRedirect, // this will be loaded in from localStorage if coming in from a referralRedirect
						print: options.print,
						eRefer: options.eRefer,
						eConsult: options.eConsult,
						eRequestSource: options.faxRedirect ? "eFax" : "eReferral",
						faxRedirect: options.faxRedirect,
						healthService: options.healthService || selectedHs,
						offeringRef: options.offeringRef,
						encryptedPt: encryptedPt,
						referralSentCallback: referralSent
					});
				});
			});
		});
	}
	function setMatch(match) {
		matches = [ match ];
		displayMatches();
		setSelectedMatch(match);
	}
	function setSelectedMatch(match) {
		if (selectedMatch !== match) {
			selectedMatch = match;
			MapView.focusOnMatch(match);
			MatchDetailsView.setSelectedHs(selectedHso || match.offeringRef);
			selectedHso = null;
			MatchDetailsView.setMatch(match, testMode, searchScoresEnabled);
		}
		showSubPanel(MatchDetailsView);
		History.save("Match");
	}
	function clearSearch() {
		criteria = {acceptingEReferrals: criteria.acceptingEReferrals, forEConsult: criteria.forEConsult};
		matches = [];
		SearchPanel.clear();
		SearchPanel.updateSearchBarPills();
		searchTitle = null;
		searchMsg = null;
		sortField = null;
		MapView.clear();
		if (testMode) {
			startSearch();
		} else {
			showSubPanel(ReferralFavouritesView);
		}
	}
	function matchFocused(match, focus) {
		if (match) {
			if (focus) {
				MapView.highlightMarker(match);
			} else {
				MapView.unhighlightMarker(match);
			}
		}
	}

	function isShowingSubPanel(controller) {
		if (controller) {
			var controllerPane = controller.getPane();
			var visible = controllerPane.parent().attr("id") === leftPane.attr("id") && controllerPane.is(":visible");
			return visible;
		}
		return false;
	}

	function showSubPanel(controller) {
		leftPane.find(".ohlSubPanel").hide();
		if (controller) {
			var controllerPane = controller.getPane();
			var inLeftPane = controllerPane.parent().attr("id") === leftPane.attr("id");
			if (inLeftPane) {
				controllerPane.show();
			}
			else {
				controllerPane.addClass("ohlSubPanel").attr("id", OUtils.uuid());
				leftPane.append(controllerPane);
			}
			subPanelId = controllerPane.attr("id");
		}
		else {
			subPanelId = null;
		}
	}
	function restoreSubPanel(id) {
		leftPane.find(".ohlSubPanel").hide();
		leftPane.find("#"+id).show();
	}
	function loadPatient() {
		var d = $.Deferred();
		require(["Patient"], function() {
			pt = new Patient();
			if (referralRedirect != null) {
				require(["OEncryption"], function() {
					OApp.getSite(function(site) {
						Referrals.decryptReferralPt(referralRedirect.referral,site, function(ptArg) {
							pt = ptArg;
							displayPatient();
							return d.resolve();
						});
					});
				});
			}
			if (!ptRefArg) {
				parsePtFromUrlArgs(pt, urlArgs);
				displayPatient();
				return d.resolve();
			}
			require(["OApp", "dwr/interface/EncryptedPatientRpc"], function() {
				OApp.getSiteNum(function(siteNum) {
					EncryptedPatientRpc.load(siteNum, ptRefArg, function(encryptedPt) {
						if (sessionStorage.getItem("ptRef")) {
							sessionStorage.removeItem("ptRef");
						}
						if (!encryptedPt) {
							OUtils.showError(OUtils.t("No patient was found with ref {{ptRefArg}}.", "NewReferral.js.no_patient_found_with_ref_message", {ptRefArg: ptRefArg}));
							return d.resolve();
						}
						require(["OEncryption"], function() {
							OSymmetricEncryption.decryptPtDto(encryptedPt, function(ptArg) {
								pt = ptArg;
								displayPatient();
								return d.resolve();
							});
						});
					});
				});
			});
		});
		return d;
		function parsePtFromUrlArgs(pt, urlArgs) {
			var demog = pt.demographics;
			var addr = demog.address;
			for (var key in urlArgs) {
				var val = urlArgs[key];
				if (key === "pt_surname") demog.surname = val;
				if (key === "pt_firstName") demog.firstName = val;
				if (key === "pt_hn") demog.hn = val;
				if (key === "pt_hnProv") demog.hnProv = val;
				if (key === "pt_hnVC") demog.hnProv = val;
				if (key === "pt_sex") demog.sex = val;
				if (key === "pt_birthDate") demog.birthDate = val;
				if (key === "pt_line1") addr.line1 = val;
				if (key === "pt_line2") addr.line2 = val;
				if (key === "pt_city") addr.city = val;
				if (key === "pt_province") addr.province = val;
				if (key === "pt_postalCode") addr.postalCode = val;
				if (key === "pt_homePhone") addr.homePhone = val;
				if (key === "pt_businessPhone") addr.businessPhone = val;
				if (key === "pt_businessPhoneExt") addr.email = val;
				if (key === "pt_mobilePhone") addr.mobilePhone = val;
				if (key === "pt_email") addr.email = val;
			}
		}
		function displayPatient() {
			var ptAddr = pt.demographics.address;
			var ptAddrStr = "";
			if (ptAddr.line1 || ptAddr.city) {
				ptAddrStr = StrUtil.concat([ptAddr.line1, ptAddr.city, ptAddr.province], ", ");
			}
			else if (ptAddr.postalCode) {
				ptAddrStr = ptAddr.postalCode;
			}
			if (ptAddrStr) {
				SearchPanel.setAddressText(ptAddrStr);
				geocodePtAddress();
			} else {
				done();
			}
			function geocodePtAddress() {
				var ptAddr = pt.demographics.address;
				var addrStr = AddressUtil.addressToString({line1: ptAddr.line1, line2: ptAddr.line2, city: ptAddr.city, province: ptAddr.province});//exclude postal
				GoogleMaps.geocodeAddress(addrStr, MapView.getCentre(), function(loc) {
					pt.demographics.address.loc = {lat: loc.lat(), lng: loc.lng()};
					MapView.addHomeMarker(loc);
					done();
				}, function(msg) {
					MapView.addErrorMsg(OUtils.t("Google was unable to locate this patient's address on the map.", "NewReferral.js.google_unable_to_locate_address_message"));
					done();
				});
			}
			function done() {
				d.resolve();
			}
		}
	}
	function setMatches(matchesIn, msg, title) {
		matches = matchesIn;
		searchTitle = title;
		searchMsg = msg;
		displayMatches();
		showSubPanel(MatchingListingsView);
	}
	function displayMatches() {
		MatchingListingsView.displayMatches({
			matches: matches,
			showWait2: showWait2,
			title: searchTitle,
			sortField: sortField,
			msg: searchMsg,
			possiblyMore: matches.length >= SEARCH_LIMIT,
			testMode: testMode,
			tryAgainCb: trySearchAgain,
			hsSearchCb: SearchPanel.setHs,
			searchText: SearchPanel.getState().searchValue,
			searchType: SearchPanel.getState().searchType,
			hadAdvancedSearchCriteria: moreThanOneAdvancedSearchCriteriaActive(),
			searchLoc: searchLoc,
			displayClearBtn: function () {
				if (SearchPanel.getState().searchText) {
					MatchingListingsView.toggleClearBtn(true);
				} else {
					MatchingListingsView.toggleClearBtn(false);
				}
			}
		});
		MapView.displayMatches(matches);
	}
	
	/**
	 * Returns a boolean to indicate if more than one advanced search criteria is specified. Mainly used to determine if
	 * there are advanced criteria that can be cleared compared to the contents of the main search field.
	 *
	 * Advanced searches use more than one criteria. For example, a text search via the main search field's autocomplete,
	 * then add the eReferral/eConsult flags through the advanced search. One can adjust the text field value,
	 * and then continue searching, with the filters applied, using the enter key.
	 */
	function moreThanOneAdvancedSearchCriteriaActive() {
		if (!criteria) {
			return false;
		}
		
		var advancedCriteriaArray = [
			criteria.acceptingEReferrals,
			criteria.forEConsult,
			criteria.text,
			criteria.offerings,
			criteria.title,
			criteria.surname,
			criteria.language,
			criteria.tags,
			criteria.maxWait1TimeDays,
			criteria.maxWait2TimeDays,
			criteria.originalListingDataSource
		];
		var numAdvancedCriteria = 0;
		for (const advancedCriteria of advancedCriteriaArray) {
			// type coercion here to boolean to check for empty array and objects (ex. empty offerings should be false, not an advanced criteria).
			if (advancedCriteria && advancedCriteria != false ) {
				++numAdvancedCriteria;
			}
			if (numAdvancedCriteria >= 2) {
				return true;
			}
		}
		return false;
	}
	
	function trySearchAgain(wholeDir, clearFilters) {
		if (wholeDir) {
			searchLoc = null;
		}
		else {
			MapView.zoomOut();
		}
		if (clearFilters) {
			// Perform standard search immediately with basic criteria only.
			SearchPanel.triggerSearch(false);
		} else {
			startSearch();
		}
	}
	function startSearch(params) {
		MapView.loaded
			.then(function() {
				return MatchingListingsView.loaded;
			})
			.then(function() {
				criteria = SearchPanel.getCriteria(params);
				if (!SearchPanel.isAdvancedSearchCriteriaEmpty()){
					SearchPanel.setSearchType(SearchType.ADVANCED);
				}
				MatchingListingsView.awaitResults();
				showSubPanel(MatchingListingsView);
				return findMatches(params);
			})
			.then(function() {
				History.save("Search Done");
			});
	}
	function findMatches(params) {
		function run() {
			var d = $.Deferred();
			criteria = SearchPanel.getCriteria(params);
			criteria.sortAsc = sortAsc;
			criteria.sortField = sortField || "MAP_DEFAULT";
			if (anyProblems(criteria)) {
				return d;
			}
			searchMsg = null;
			searchTitle = criteria.searchTitle;
			var crit = JSON.parse(JSON.stringify(criteria));
			for (var i = 0; i < filterTags.length; i++) {
				if (crit.tags.indexOf(filterTags[i]) === -1) {
					crit.tags.push(filterTags[i]);
				}
			}
			findOceanMatches(crit).then(function(result) {
				matches = result.matches;
				showWait2 = result.showWait2;
				if (matches.indexOf(selectedMatch) === -1) {
					selectedMatch = null;
				}
				displayMatches();
				d.resolve();
			});
			return d;
		}
		function findOceanMatches(criteria) {
			var d = $.Deferred();
			ReferralPortalRpc.findListings(criteria, function(results) {
				d.resolve(results);
			});
			return d.promise();
		}
		function anyProblems(criteria) {
			if (!SearchPanel.hasAddress() && criteria.radiusKm) {
				bail(OUtils.t("Please specify a nearby address.", "NewReferral.js.specify_nearby_address_message"));
				return true;
			}
			return false;
			function bail(error) {
				matches = [];
				searchMsg = error;
				displayMatches();
				showSubPanel(MatchingListingsView);
				return true;
			}
		}
		return run();
	}

	var LocalityPref = (function() {
		var LOCALITY_KEY = "Address";
		function getKey(locality) {
			return LOCALITY_KEY+"-"+locality;
		}
		return {
			save: function(locality) {
				OUtils.setLocalStorage(LOCALITY_KEY, locality);
			},
			load: function() {
				return OUtils.getLocalStorage(LOCALITY_KEY)
					|| OUtils.getCookie(LOCALITY_KEY, "");
			},
			saveGeocode: function(locality, result) {
				OUtils.setLocalStorage(getKey(locality), JSON.stringify(result));
			},
			readGeocode: function(locality) {
				return JSON.parse(OUtils.getLocalStorage(getKey(locality)) || "null");
			}
		};
	}());

	function referralSent(args) {
		require(["referrals/ReferralConfirmationDialog"], function(ReferralConfirmationDialog) {
			OApp.initTranslationFlagAndI18n({ namespaces: ["pn-pe"] }, function() {
				ReferralConfirmationDialog.show(args);
			});
		});
	}
	
	var SearchType = {
		TEXT: {name: "TEXT", makeLabel: (searchTerm) => OUtils.t("Any listing containing: {{term}}", "NewReferral.js.search_type_text", {term: searchTerm})}, 
		HS: {name: "HS", makeLabel: (searchTerm) => searchTerm}, 
		TITLE: {name: "TITLE", makeLabel: (searchTerm) => OUtils.t("Name: {{term}}...", "NewReferral.js.search_type_title", {term: searchTerm})}, 
		SURNAME: {name: "SURNAME", makeLabel: (searchTerm) => OUtils.t("Surname: {{term}}...", "NewReferral.js.search_type_surname", {term: searchTerm})}, 
		ADVANCED: {name: "ADVANCED", makeLabel: () => OUtils.t("Advanced Search...", "NewReferral.js.search_type_advanced")}
	};

	var SearchPanel = (function() {
		var pane, searchTextF, radiusKmF, searchLocationContainer, patientFacing;
		var searchType, searchValue;

		function init(spec) {
			pane = spec.pane;
			patientFacing = !!spec.patientFacing;
			if (patientFacing) {
				pane.hide();
			}
			searchLocationContainer = pane.findIt("#search-location-container");
			SearchTextField.init();
			AddressField.init();
		}
		function setSearchType(sType) {
			searchType = sType;
		}
		function setHs(hs) {
			selectedHs = hs;
			SearchTextField.setText(Referrals.HealthService[selectedHs]);
			searchValue = null;
			setSearchType(SearchType.HS);
			startSearch();
		}
		function getCriteria(params) {
			params = params || {};
			criteria.searchTitle = null;
			if (!isAdvancedSearch()) {
				if (!params.avoidResetCriteria) {
					criteria = {};
				}
				criteria.tags = [];
				criteria.offerings = null;
				if (searchType === SearchType.HS) {
					criteria.offerings = selectedHs ? [{service: selectedHs}] : null;
					criteria.searchTitle = Referrals.HealthService[selectedHs];
				}
				else if (searchType === SearchType.SURNAME) {
					criteria.surname = searchValue;
				}
				else if (searchType === SearchType.TITLE) {
					criteria.title = searchValue;
				}
				else {
					criteria.text = searchValue;
				}
			}
			criteria.enableSearchScores = searchScoresEnabled;
			if (testMode) {
				criteria.testListing = true;
				criteria.searchTitle = criteria.searchTitle || OUtils.t("Test Listings", "NewReferral.js.test_listings_label");
				criteria.demoListing = null;
			} else {
				criteria.demoListing = false;
			}
			var b = MapView.getBounds();
			if (b) {
				criteria.topLeft = {lng: b.getSouthWest().lng(), lat: b.getNorthEast().lat()};
				criteria.bottomRight = {lng: b.getNorthEast().lng(),  lat: b.getSouthWest().lat()};
			}
			criteria.limit = SEARCH_LIMIT;
			criteria.nearGeoLoc = searchLoc;
			criteria.preciseLoc = preciseLoc;
			if (selfReferral) {
				criteria.selfReferral = true;
			}
			return criteria;
		}
		function clear() {
			if (patientFacing) {
				pane.hide();
			} else {
				pane.show();
			}
			SearchTextField.setText("");
			SearchTextField.focus();
			setSearchType(null);
			searchValue = null;
		}
		function getState() {
			return {
				searchText: SearchTextField.val(),
				searchType: searchType,
				searchValue: searchValue,
				addressText: AddressField.val()
			};
		}
		function setState(state) {
			SearchTextField.setText(state.searchText);
			searchType = state.searchType;
			searchValue = state.searchValue;
			if (state.addressText && state.addressText !== AddressField.val()) {
				AddressField.setText(state.addressText);
				lookupAddress();
			}
		}
		function showAdvancedSearch() {
			require(["referrals/AdvancedSearchPopup"], function(AdvancedSearchPopup) {
				AdvancedSearchPopup.loadAndShow({
					criteria: criteria,
					searchCallback: function() {
						SearchTextField.setAdvancedSearch(true);
						searchValue = null;
						searchType = SearchType.ADVANCED;
						updateSearchBarPills();
						startSearch();
					}
				});
			});
		}
		
		function isAdvancedSearchCriteriaEmpty() {
			// I removed the criteria === {} check since it's always false - if we really need to check for an empty object we should run it through a loop with Object.hasOwn
			if (criteria == null) {
				return true;
			}

			return !criteria.acceptingEReferrals && !criteria.forEConsult
				&& !criteria.text && !criteria.offerings && !criteria.title
				&& !criteria.surname && !criteria.language && (!criteria.tags || criteria.tags.length === 0)
				&& (!criteria.maxWait1TimeDays || criteria.maxWait1TimeDays === '')
				&& (!criteria.maxWait2TimeDays || criteria.maxWait2TimeDays === '')
				&& !criteria.originalListingDataSource;
		}
		
		function updateSearchBarPills() {
			var eReferralPill = pane.findIt("#eReferralPill");
			var eConsultPill = pane.findIt("#eConsultPill");
			var pillContainer = pane.findIt("#searchBarPillContainer");
			if (criteria.acceptingEReferrals) {
				eReferralPill.show();
			} else {
				eReferralPill.hide();
			}
			if (criteria.forEConsult) {
				eConsultPill.show();
			} else {
				eConsultPill.hide();
			}
			if (criteria.acceptingEReferrals || criteria.forEConsult) {
				pillContainer.show();
			} else {
				pillContainer.hide();
			}
			if (isAdvancedSearchCriteriaEmpty()) {
				SearchTextField.setAdvancedSearch(false);
			}
		}
		function isAdvancedSearch() {
			return searchType === SearchType.ADVANCED;
		}
		function hasAddress() {
			return AddressField.val() ? true : false;
		}
		function lookupAddress() {
			return AddressField.lookupAddress();
		}
		var SearchTextField = (function() {
			var textF, moreCharsMsg;
			var origPH, popupOpen = false, valueAtLastAutocomplete;
			var ADVANCED_SEARCH_MARKER = OUtils.t("advanced search...", "NewReferral.js.advanced_search_placeholder");
			function init() {
				moreCharsMsg = pane.findIt("#moreChars");
				textF = pane.findIt("#searchText").keyup(function() {
					if (!textF.val()) {
						clearSearch();
					}
				}).focus(function() {
					resetDisplay();
					showPopup();
				}).change(function() {
					if (textF.val() !== valueAtLastAutocomplete) {
						searchValue = null;
						textF.focus().select();
						showPopup();
					}
				}).autocomplete({
					minLength: 2,
					source: function(request, response) {
						if (!request.term || request.term.length < 2) {
							response({
								value: "",
								label: "",
								internalValue: "INVALID",
								sortScore: 0
							});							
						} else {
							ReferralPortalRpc.findHealthServices(request.term, function(matches) {
								let searchOptions = [];
								Object.values(SearchType).forEach(type => {
									if (type === SearchType.HS) {
										matches.forEach(match => {
											match.label = SearchType.HS.makeLabel(match.label);
											searchOptions.push(match);
										});
									} else {
										searchOptions.push({
											value: request.term,										
											label: type.makeLabel(request.term),
											internalValue: type.name,
											sortScore: 0	
										});							
									}
								});
								response(searchOptions);
							});
						}
					},
					select: function(e, ui) {
						window.setTimeout(function() {
							searchSelected(ui.item, true);

						}, 0);
					},
					open: function(e, ui) {
						popupOpen = true;
						moreCharsMsg.hide();
					},
					close: function(e, ui) {
						window.setTimeout(function() {
							popupOpen = false;
						}, 100);
					}
				}).blur(function() {
					moreCharsMsg.hide();
				});
				origPH = textF.attr("placeholder");
				textF.val("");
				resetField();
				OUtils.onEnter(textF, () => {
					searchValue = textF.val();
					continueSearchWithFilters();
				});
				pane.findIt("#searchTextBtn").click(function() {
					continueSearchWithFilters();
				});
				pane.findIt("#advancedSearchBtn").click(function() {
					showAdvancedSearch();
				});
				pane.findIt("#eReferralPillClose").click(function() {
					criteria.acceptingEReferrals = null;
					updateSearchBarPills();
					startSearch();
				});
				pane.findIt("#eConsultPillClose").click(function() {
					criteria.forEConsult = null;
					updateSearchBarPills();
					startSearch();
				});
				if (searchArg) {
					textF.val(searchArg);
					searchValue = searchArg;
					setSearchType(SearchType.TITLE);
				}
				else if (selectedHs) {
					textF.val(Referrals.HealthService[selectedHs]);
					setSearchType(SearchType.HS);
				}
				else {
					textF.focus().select();
				}
			}
			function resetDisplay() {
				if (searchValue && textF.val().length > 0) {
					textF.val(searchValue);
				}
			}
			function searchSelected(item, avoidResetCriteria) {
				textF.autocomplete("close");
				if (item.internalValue === "ADVANCED") {
					showAdvancedSearch();
					return;
				}
				textF.val(item.label);
				if (Referrals.HealthService[item.internalValue]) {
					selectedHs = item.internalValue;
					setSearchType(SearchType.HS);
					// clear the text search value when an HS is selected
					searchValue = null;
				}
				else {
					selectedHs = null;
					setSearchType(SearchType[item.internalValue]);
					searchValue = item.value;
				}
				valueAtLastAutocomplete = item.label;
				searchLoc = MapView.getCentre();
				startSearch({avoidResetCriteria: avoidResetCriteria});
				textF.blur();
				return false;
			}
			function showPopup() {
				valueAtLastAutocomplete = textF.val();
				textF.autocomplete("search", textF.val());
				setSearchType(null);
			}
			function continueSearchWithFilters() {
				triggerSearch(true);
			}
			function triggerSearch(avoidResetCriteria) {
				if (!avoidResetCriteria) {
					criteria.acceptingEReferrals = null;
					criteria.forEConsult = null;
					updateSearchBarPills();
					resetField();
				}
				let val = searchValue && textF.val().length > 0? searchValue : textF.val();
				if (val.length < 2) {
					moreCharsMsg.show();
				} else {
					let match = { value: val, label: SearchType.TEXT.makeLabel(val), internalValue: SearchType.TEXT.name, sortScore: 0};
					searchSelected(match, avoidResetCriteria);
					moreCharsMsg.hide();
				}
			}
			function resetField() {
				setAdvancedSearch(false);
			}
			function val() {
				return textF.val();
			}
			function setText(text) {
				textF.val(text);
			}
			function focus() {
				textF.focus();
			}
			function clear() {
				textF.val("");
			}
			function setAdvancedSearch(value) {
				//Text field no longer reflects the search criteria
				textF.val(""); 
				moreCharsMsg.hide();

				// addClass/removeClass doesn't work with svg in current version of JQuery :D
				if (value) {
					textF.attr("placeholder", ADVANCED_SEARCH_MARKER);
					pane.findIt("#advancedSearchBtnIcon").attr("class", "set-adv-search-btn-blue");
				} else {
					textF.attr("placeholder", origPH);
					pane.findIt("#advancedSearchBtnIcon").attr("class", "set-adv-search-btn-white");
				}
			}
			return {
				val: val,
				setText: setText,
				setAdvancedSearch: setAdvancedSearch,
				focus: focus,
				init: init,
				showAdvancedSearch: showAdvancedSearch,
				updateSearchBarPills: updateSearchBarPills,
				isAdvancedSearchCriteriaEmpty: isAdvancedSearchCriteriaEmpty,
				setSearchType: setSearchType,
				triggerSearch: triggerSearch,
			};
		}());
		var AddressField = (function() {
			var field;
			var enterPressed = null;
			function init() {
				field = pane.findIt("#displayAddress").autocomplete({
					minLength: 4,
					autoFocus: true,
					source: function(request, response) {
						addressQuery(request.term, response);
					},
					select: function(e, ui) {
						matchSelected(ui.item);
						return false;
					},
					open: function(e, ui) {
						if (enterPressed) {
							field.autocomplete("close");
							if (!SearchTextField.val()) {
								SearchTextField.focus();
							}
							else {
								field.blur();
							}
						}
						enterPressed = false;
					},
					response: function(e, ui) {
						if (enterPressed && ui.content.length > 0) {
							matchSelected(ui.content[0]);
						}
					}
				}).click(function() {
					field.select();
				});
				OUtils.onEnter(field, function() {
					enterPressed = true;
					field.autocomplete("search", field.val());
				});
				var locality = LocalityPref.load();
				var regionTag = loadRegionTag();
				if (locality) {
					field.val(locality);
				}
				else if (regionTag) {
					field.val(StrUtil.cap(regionTag));
				}
			}
			function doComponentsIncludeOntario(address_components) {
				for (var i in address_components) {
					var component = address_components[i];
					if (component.short_name === "ON") {
						return true;
					}
				}
				return false;
			}
			function lookupAddress() {
				return $.Deferred(function(d) {
					var regionalCentrePromise = $.Deferred();
					ReferralFavouritesView.refreshRegion(regionalCentrePromise);
					geocodeIt(field.val()).then(function() {
						var centre = MapView.getCentre();
						if (centre) {
							regionalCentrePromise.resolve(centre);
						}
						d.resolve();
					});
				});
				function geocodeIt(addr) {
					return $.Deferred(function(d) {
						var cachedResult = LocalityPref.readGeocode(addr);
						if (cachedResult) {
							return geocoded(cachedResult);
						}
						GoogleMaps.geocodeAddress(addr, null, function(loc, result, locality) {
							LocalityPref.saveGeocode(locality, result);
							// jsonify the result to make it consistent with the cached results:
							geocoded(JSON.parse(JSON.stringify(result)));
						}, function failed(msg) {
							console.log("geocodeAddress failed: " + msg);
							d.resolve();
						});
						function geocoded(result) {
							var loc = result.geometry.location;
							searchLoc = loc;
							inON = doComponentsIncludeOntario(result.address_components);
							preciseLoc = result.address_components.filter(function(c) { return c.types.indexOf("street_number") >= 0; }).length > 0;
							MapView.displayAddress({addressText: result.formatted_address, loc: loc, showAddressPopup: false});
							d.resolve();
						}
					});
				}
			}
			function addressQuery(text, response) {
				GoogleMaps.geocodeAddress(text, null, function(loc, result1, locality, results) {
					var matches = [];
					for (var i = 0; i < results.length; i++) {
						var result = results[i];
						var addr = result.formatted_address.replace(/, Canada$/, "");
						matches.push({value: addr, loc: result.geometry.location, locality: GoogleMaps.getLocality(result)});
					}
					response(matches);
				});
			}
			function matchSelected(item) {
				field.val(item.value);
				LocalityPref.save(item.locality);
				lookupAddress().then(function() {
					startSearch();
				});
			}
			function loadRegionTag() {
				var regionTag = urlArgs["region"] || null;
				if (window.location.pathname.match(/^\/region\//)) {
					regionTag = window.location.pathname.substr("/region/".length);
					// check for trailing /
					if (regionTag.match(/\//)) {
						regionTag = regionTag.substr(0, regionTag.length - 1);
					}
				}
				return regionTag;
			}
			function val() {
				return field.val();
			}
			function setText(text) {
				field.val(text);
			}
			return {
				init: init,
				val: val,
				setText: setText,
				lookupAddress: lookupAddress
			};
		}());
		return {
			init: init,
			getState: getState,
			setState: setState,
			getCriteria: getCriteria,
			clear: clear,
			setHs: setHs,
			setAddressText: AddressField.setText,
			lookupAddress: lookupAddress,
			hasAddress: hasAddress,
			showAdvancedSearch: SearchTextField.showAdvancedSearch,
			updateSearchBarPills: SearchTextField.updateSearchBarPills,
			isAdvancedSearchCriteriaEmpty: SearchTextField.isAdvancedSearchCriteriaEmpty,
			setSearchType: SearchTextField.setSearchType,
			triggerSearch: SearchTextField.triggerSearch
		};
	}());

	$(function() {
		OApp.initTranslationFlagAndI18n({ namespaces: ["pn-pe"] }, function() {
			init();
		});
		require(["referrals/SendReferralDialog", "questionnaires/core/QuestionnaireApplication", "FileUpload"], function() {});//pre-load
	});

	return {
		init: init
	};
});